import React from 'react'
//
import { FaWhatsapp } from 'react-icons/fa'
//
import { constants } from '../../constants'
//
import styles from './botao-whats.module.css';

export default function BotaoWhats() {
  
  if(!constants.links.linkWpp) return null;

  return (
    <a
      className={styles['link-whats']}
      href={constants.links.linkWpp}
    >
      <FaWhatsapp />
    </a>
  )
}
