import React from 'react'
//
import DefaultHeader from '../../../components/DefaultHeader';
//
import { constants } from '../../../constants';
//
import { FaWifi, FaConnectdevelop, FaCreditCard } from "react-icons/fa";
import { RiCustomerServiceFill } from "react-icons/ri";
//
import styles from './banner-quem-somos.module.css';

export default function BannerQuemSomos() {
  return (
    <section id='quem-somos' className={styles['container']}>
        
        <DefaultHeader
            icon={<FaWifi/>}
            subtitle={constants.texts.nomeEmpresa}
            title='Somos diferentes de tudo que você já viu!'
        />

        <div className={styles['cards']}>

            <div className={styles['card']}>
                <FaConnectdevelop className={styles['icon-bg-card']}/>
                <FaConnectdevelop className={styles['icon-card']}/>
                <div className={styles['title-card']}>
                    {constants.texts.nomeEmpresa} - O Digital Chegou!
                </div>
                <div className={styles['txt-card']}>
                    Se vivemos na era digital por que usar soluções do passado?
                     Acabou as filas e as musiquinhas enjoadas.
                </div>
            </div>

            <div className={styles['card']}>
                <RiCustomerServiceFill className={styles['icon-bg-card']}/>
                <RiCustomerServiceFill className={styles['icon-card']}/>
                <div className={styles['title-card']}>
                    Atendimento Digital
                </div>
                <div className={styles['txt-card']}>
                Resolva tudo de forma digital, de um jeito simples,
                 rápido e eficiente.<br/>Chega de burocracias.
                </div>
            </div>

            <div className={styles['card']}>
                <FaCreditCard className={styles['icon-bg-card']}/>
                <FaCreditCard className={styles['icon-card']}/>
                <div className={styles['title-card']}>
                    Pagamento Digital
                </div>
                <div className={styles['txt-card']}>
                    Na {constants.texts.nomeEmpresa} você não precisa ir na lotérica pagar sua fatura,
                     isso é coisa do passado! Você faz tudo pelo aplicativo.
                </div>
            </div>

        </div>
    </section>
  )
}
