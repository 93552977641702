import React from 'react';
//
import styles from './botao.module.css';

export default function Botao({ 
    icon, 
    text, 
    type, 
    onClick, 
    disabled,
    color, 
    style, 
    marginLeft, 
    href, 
    target 
}) {
    if (type !== 'link') {
        return (
            <button
                className={styles['botao']+' '+styles[color ? color : 'primary']}
                type={type ? type : 'button'}
                onClick={onClick}
                disabled={disabled}
                style={style}
            >
                {icon}
                {text &&
                    <span style={marginLeft ? { marginLeft: marginLeft + 'rem' } : {}}>
                        {text}
                    </span>
                }
            </button>
        );
    } else {
        return (
            <a
                className={styles['botao']+' '+styles[color ? color : 'primary']}
                href={href}
                target={target}
                style={style}
                rel="noreferrer"
            >
                {icon}
                {text &&
                    <span style={marginLeft ? { marginLeft: marginLeft + 'rem' } : {}}>
                        {text}
                    </span>
                }
            </a>
        );
    }
}
