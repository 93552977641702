import React from 'react'

export function OnlinePresentationSVG({color, style}) {
    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 300" style={style}>
            <title>_</title>
            <path d="M101.89,58.55C68,67.72,41.17,89.21,36.94,128.76c-3.56,33.33,3.14,89.18,60,100.31,55,10.78,253.42,32.23,266.47-37.84,7.42-39.86,8.25-107.48-54.65-129C266.12,47.57,174.62,38.89,101.89,58.55Z" fill="#e6e6e6" opacity="0.3"></path>
            <ellipse cx="264.37" cy="249.75" rx="99.04" ry="9.9" fill="#e6e6e6" opacity="0.45"></ellipse>
            <ellipse cx="98.86" cy="249.75" rx="44.29" ry="9.9" fill="#e6e6e6" opacity="0.45"></ellipse>
            <path d="M110.75,109.52s15.3,3.9,15.41,16.57-6.78,34.85,3.06,36.66S145,137.18,145,137.18l7,2.83s-7.47,46.77-33.26,39.06S110.75,109.52,110.75,109.52Z" fill={color ? color : "#CC3366"}></path>
            <path d="M110.75,109.52s15.3,3.9,15.41,16.57-6.78,34.85,3.06,36.66S145,137.18,145,137.18l7,2.83s-7.47,46.77-33.26,39.06S110.75,109.52,110.75,109.52Z" fill="#fff" opacity="0.44"></path>
            <path d="M146.58,137.84s.4-6.77,3.12-8.7,2.26,3,2.26,3,2.72-3.17,5-1.7-5,9.62-5,9.62Z" fill="#f4a28c"></path>
            <path d="M98.22,81.78a33.54,33.54,0,0,1-.81,8.91A2.7,2.7,0,0,1,94,92.51a6,6,0,0,1-4.49-4.69l-1.41-4.77A4.83,4.83,0,0,1,90.47,78C93.59,75.91,98.23,78.49,98.22,81.78Z" fill="#f4a28c"></path>
            <polygon points="88.5 85.49 86.5 103.04 96.25 103.45 93.79 90.51 88.5 85.49" fill="#f4a28c"></polygon>
            <path d="M96.87,82a21.61,21.61,0,0,1-5-.59,4.49,4.49,0,0,1-.2,4.93,3.53,3.53,0,0,1-2.35,1.53,1.8,1.8,0,0,1-2-1.91l.28-5.26a5.52,5.52,0,0,1,3-4.78,21.83,21.83,0,0,1,2.36-1c2-.72,4.89.73,6.73-.54a1.3,1.3,0,0,1,2,.93c.22,2.12-.29,5.42-3.19,6.45A5.14,5.14,0,0,1,96.87,82Z" fill="#24285b"></path>
            <path d="M92.3,86.15s0-2.07-1.52-2-1.64,3.1.46,3.46Z" fill="#f4a28c"></path>
            <path d="M98.2,85.18l1.41,2.13A.86.86,0,0,1,99,88.64L96.94,89Z" fill="#f4a28c"></path>
            <path d="M93.65,90.93a6.41,6.41,0,0,1-3.52-1s.14,3.26,4.37,5.48Z" fill="#ce8172" opacity="0.31"></path>
            <path d="M76.84,243s-.81,3.09-3.49,3.57-3.16,3.18-.66,3.65S81.18,247,81.18,247l-.53-3.89Z" fill={color ? color : "#CC3366"}></path>
            <path d="M120.4,243s.81,3.09,3.49,3.57,3.16,3.18.66,3.65-8.49-3.23-8.49-3.23l.53-3.89Z" fill={color ? color : "#CC3366"}></path>
            <path d="M129.16,130.29a27.32,27.32,0,0,0-24.25-27.61c-13.17-1.55-37-3.45-46.21,1.7-13,7.28,8.82,74.84,8.82,74.84h44.39S128.62,152.64,129.16,130.29Z" fill={color ? color : "#CC3366"}></path>
            <path d="M71.07,110.23s10,2.76,7.31,18.53h13L91.59,146a38.06,38.06,0,0,0,20.2,33.13l.12.06H67.52l-1.74-5.64Z" opacity="0.08"></path>
            <path d="M72.69,117.55a12,12,0,0,0-17.24-11.89c-9.18,4.57-18.84,13.68-21.24,31.83-4.28,32.29,4.61,41.21,21.28,31.73C66.42,163,70.9,135.65,72.69,117.55Z" fill={color ? color : "#CC3366"}></path>
            <path d="M72.69,117.55a12,12,0,0,0-17.24-11.89c-9.18,4.57-18.84,13.68-21.24,31.83-4.28,32.29,4.61,41.21,21.28,31.73C66.42,163,70.9,135.65,72.69,117.55Z" fill="#fff" opacity="0.46"></path>
            <rect x="42.67" y="123.57" width="40.26" height="50" fill="#ffd200"></rect>
            <path d="M55.49,123.57s2.82-6.27,8.46-3.91S65.75,129.47,55.49,123.57Z" fill="#f4a28c"></path>
            <rect x="49.34" y="132.03" width="25.39" height="18.46" fill="#fff" opacity="0.58"></rect>
            <rect x="49.34" y="156.48" width="25.39" height="6.49" fill="#fff"></rect>
            <path d="M67.52,179.22l7.2,64h8.21S80.78,194.86,93.14,187s18.77-7.82,18.77-7.82Z" fill="#24285b"></path>
            <path d="M111.91,179.22l11,64h-7.69s-9.49-47.57-32.31-55C82.93,188.19,96.52,180.76,111.91,179.22Z" fill="#24285b"></path>
            <rect x="309.38" y="232.27" width="47.69" height="15.17" fill="#e6e6e6"></rect>
            <rect x="298.95" y="217.43" width="47.69" height="15.17" fill="#e6e6e6" opacity="0.43"></rect>
            <path d="M333.89,205.37s-9.52-2.6-11.59-11.46c0,0,14.75-3,15.17,12.24Z" fill={color ? color : "#CC3366"} opacity="0.58"></path>
            <path d="M335.05,204.42s-6.65-10.51-.79-20.33c0,0,11.21,7.12,6.23,20.36Z" fill={color ? color : "#CC3366"} opacity="0.73"></path>
            <path d="M336.77,204.43s3.51-11.1,14.13-13.2c0,0,2,7.21-6.88,13.23Z" fill={color ? color : "#CC3366"}></path>
            <polygon points="329.87 204.18 331.79 217.38 343.94 217.43 345.73 204.25 329.87 204.18" fill="#24285b"></polygon>
            <rect x="179.67" y="40.35" width="138.88" height="207.52" fill="#ccc"></rect>
            <rect x="191.16" y="64" width="114.96" height="146.05" fill="#fff"></rect>
            <rect x="237.36" y="48.46" width="38.94" height="4.73" opacity="0.12"></rect>
            <circle cx="224.9" cy="51.33" r="5.14" fill="#e6e6e6"></circle>
            <path d="M227.87,128.47l-8.65,9A6.46,6.46,0,0,1,209,136.3L197.8,117.23a6.24,6.24,0,0,1-.68-1.69c-.49-2-1.83-7-3.32-7.59-1.88-.7-2.35,3.92-2.35,3.92s-5.41-3.61-7.75,0c-1.4,2.14,2,5,5,7a12.94,12.94,0,0,1,5.29,6.94c2.87,9.07,9.88,28.32,18.12,29.91,10.81,2.08,29.89-14.2,29.89-14.2Z" fill="#f4a28c"></path>
            <path d="M248.86,98.57s-34.43,5.57-30.2,29.36c3.06,17.23,12,16,11.58,29.14l-.2,8.35,36.62-1.11s-2.33-16.48,2.25-28.2S286.06,95.44,248.86,98.57Z" fill={color ? color : "#CC3366"}></path>
            <polygon points="256.83 78.01 258.43 98.77 246.92 98.83 250.38 83.7 256.83 78.01" fill="#f4a28c"></polygon>
            <path d="M259.17,103s-17.89,5.36-12.25,20.77,14.62,25.18,5.58,30.1S230,165.42,230,165.42H261l7.36-8,6.37-23.18Z" opacity="0.09"></path>
            <path d="M249.48,87a7.57,7.57,0,0,0,3.89-1.91s-.47,3.78-5,7.19Z" fill="#ce8172" opacity="0.31"></path>
            <path d="M245.28,76.26s-.7,6.32.26,10.56a3.19,3.19,0,0,0,3.84,2.39c2.13-.52,4.85-1.83,5.64-5.17l2-5.5a5.7,5.7,0,0,0-2.36-6.16C251.17,69.72,245.51,72.4,245.28,76.26Z" fill="#f4a28c"></path>
            <polygon points="230.04 165.42 223.07 210.05 272.07 210.05 266.67 164.31 230.04 165.42" fill="#24285b"></polygon>
            <path d="M251.9,81.86s.11-2.44,1.94-2.21,1.7,3.77-.8,4Z" fill="#f4a28c"></path>
            <path d="M245,80.27l-1.83,2.4a1,1,0,0,0,.6,1.61l2.42.52Z" fill="#f4a28c"></path>
            <path d="M253.84,79.65S246.2,98.4,252.32,102s17,2.81,18.47-5.18-6.39-10-6.46-21.17-21.55-10.39-21.78-4S253.84,79.65,253.84,79.65Z" fill="#24285b"></path>
            <path d="M259.17,103a9.15,9.15,0,0,1,13.53,2.11,49,49,0,0,1,8,29.61c-1.25,22.23,3.52,35.7-19.74,30.7-17-3.65-37.6-31.48-37.6-31.48s-6.89-4.57-1.56-11.3,9.39,9.24,9.39,9.24,27.89,23.26,33.53,16.49C269.66,142.43,244.71,115.24,259.17,103Z" fill="#f4a28c"></path>
            <circle cx="290.93" cy="79" r="8.71" fill={color ? color : "#CC3366"}></circle>
            <circle cx="279.65" cy="79" r="8.71" fill={color ? color : "#CC3366"} opacity="0.32"></circle>
            <circle cx="249.16" cy="188.36" r="15.18" fill={color ? color : "#CC3366"}></circle>
            <path id="Shape" d="M248.31,189.45a6.76,6.76,0,0,1-2-3.81.58.58,0,0,1,.17-.48l1.28-1.28a.56.56,0,0,0,.08-.71l-2-3.18a.58.58,0,0,0-.74-.21l-3.29,1.55a.56.56,0,0,0-.31.57,17.15,17.15,0,0,0,14.43,14.44.56.56,0,0,0,.57-.32l1.55-3.28a.57.57,0,0,0-.21-.74L254.6,190a.57.57,0,0,0-.71.08l-1.29,1.29a.59.59,0,0,1-.48.16,6.72,6.72,0,0,1-3.81-2Z" fill="#fff"></path>
            <rect x="284.42" y="107.42" width="34.95" height="53.58" fill="#ccc" opacity="0.09"></rect>
            <rect x="288.36" y="103.1" width="68.27" height="53.58" fill="#e6e6e6"></rect>
            <rect x="288.36" y="97.39" width="68.27" height="5.71" fill="#b3b3b3"></rect>
            <path d="M313.73,104.66,297,118s-15.7,15.75-5.85,26.05,24.21-4.78,24.21-4.78Z" fill={color ? color : "#CC3366"}></path>
            <path d="M313.73,104.66,297,118s-15.7,15.75-5.85,26.05,24.21-4.78,24.21-4.78Z" opacity="0.08"></path>
            <rect x="297.54" y="108.79" width="1.93" height="12.49" transform="translate(-7.29 209.01) rotate(-38.16)" fill="#24285b"></rect>
            <path d="M297,118s2.6-3.07,4.94-2.06,1.73,5.67-2.35,5.75S297,118,297,118Z" fill="#f4a28c"></path>
            <path d="M321.61,84.05a24.5,24.5,0,0,0,.31,6.62,2,2,0,0,0,2.44,1.45,4.44,4.44,0,0,0,3.47-3.33l1.2-3.48a3.59,3.59,0,0,0-1.57-3.83C325.21,79.86,321.7,81.62,321.61,84.05Z" fill="#f4a28c"></path>
            <polygon points="328.81 85.94 330.83 98.86 323.64 99.47 325.06 89.82 328.81 85.94" fill="#f4a28c"></polygon>
            <path d="M321.51,86.56,320.4,88.1a.64.64,0,0,0,.4,1l1.52.29Z" fill="#f4a28c"></path>
            <path d="M324.66,91.92A4.75,4.75,0,0,0,327,90.54a7.42,7.42,0,0,1-2.74,4.74Z" fill="#ce8172" opacity="0.31"></path>
            <path d="M308.07,156.55h27.58c8.76-13.79,28.54-50.5-3.74-57.69l-9,.76s-17.76,5.22-17.05,21.92C306.48,135,307.64,150.9,308.07,156.55Z" fill={color ? color : "#CC3366"}></path>
            <path d="M308.08,156.55h27.27c3.78-7.79,15.44-35,.33-47a15.32,15.32,0,0,0-7.85,14.35c.59,10.15-1.08,13.79-11.55,12.62s-9.37,3.37-9.37,3.37Z" opacity="0.08"></path>
            <path d="M321.51,78.94s-2.25,5.52,2.13,6,1.61,3.44,4.19,3.88,5.52-5.82,3-9S322.77,76.4,321.51,78.94Z" fill="#24285b"></path>
            <path d="M332.8,112.83a6.6,6.6,0,0,1,11.11-5.54,35.41,35.41,0,0,1,10.82,22.17c2.61,21.79-5.06,43.22-38.78,8.6l3.43-4s16.76,11.57,21.71,9.59C344.88,142.17,334.32,126.5,332.8,112.83Z" fill={color ? color : "#CC3366"}></path>
            <path d="M332.8,112.83a6.6,6.6,0,0,1,11.11-5.54,35.41,35.41,0,0,1,10.82,22.17c2.61,21.79-5.06,43.22-38.78,8.6l3.43-4s16.76,11.57,21.71,9.59C344.88,142.17,334.32,126.5,332.8,112.83Z" fill="#fff" opacity="0.46"></path>
            <rect x="298.56" y="118.43" width="17.89" height="26.12" transform="translate(-14.07 39.64) rotate(-7.21)" fill="#ffd200"></rect>
            <path d="M316.84,137s-6.94-3.35-5.25-5.87,7.79,2.93,7.79,2.93Z" fill="#f4a28c"></path>
            <rect x="179.85" y="175.64" width="43.86" height="46.11" fill="#ccc" opacity="0.09"></rect>
            <rect x="159.74" y="170.14" width="58.76" height="46.11" fill="#e6e6e6"></rect>
            <rect x="159.74" y="164.26" width="58.76" height="5.89" fill="#b3b3b3"></rect>
            <path d="M171,216.26l-1.9-22.67s9.3-1.24,10.13-1c.51.13.63,13.48.64,23.69Z" fill="#f4a28c"></path>
            <path d="M187.24,160.05s3.56-2.75,6.16-.6.41,10.27,6.37,8.65c0,0-6.62,5-10.7-3.84Z" fill="#24285b"></path>
            <path d="M189.72,165.8s2.35-.23,1.53-3.45-3.47-3.22-4.26-2.42Z" fill={color ? color : "#CC3366"}></path>
            <path d="M182.83,163.76a17.24,17.24,0,0,0,.89,4.54,1.39,1.39,0,0,0,1.83.75,3.11,3.11,0,0,0,2.06-2.65l.47-2.52a2.51,2.51,0,0,0-1.48-2.49A2.67,2.67,0,0,0,182.83,163.76Z" fill="#f4a28c"></path>
            <polygon points="188.59 165.32 189.99 174.33 184.97 174.75 185.94 168.19 188.59 165.32" fill="#f4a28c"></polygon>
            <path d="M183,165.51l-.61,1.17a.44.44,0,0,0,.37.65l1.09,0Z" fill="#f4a28c"></path>
            <path d="M185.91,168.38a3.39,3.39,0,0,0,1.57-.92,2.43,2.43,0,0,1-1.85,2.8Z" fill="#ce8172" opacity="0.31"></path>
            <path d="M188.4,165.36s-.14-1.07.67-1.1,1,1.5,0,1.8Z" fill="#f4a28c"></path>
            <path d="M183.39,163.55a7.9,7.9,0,0,0,2.66-.19s.78,3,3.28,3.65c0,0,2-4.76-1-6.35-2.49-1.33-4.35-2.53-6.19-.62A2.08,2.08,0,0,0,183.39,163.55Z" fill="#24285b"></path>
            <path d="M186.12,165s0-1.24,1-1.12,1.09,1.57-.11,2.22Z" fill="#f4a28c"></path>
            <path d="M174.6,216.26l-.43-5.32a10.31,10.31,0,0,0-2.16-5.48c-14.64-19,13-30.71,13-30.71l5-.42c27.12,6.09,17,30,9.91,41.93Z" fill={color ? color : "#CC3366"}></path>
            <path d="M174.6,216.26l-.29-4.07s1.77-7.21,10.38-1.23,13.44-.92,6-9.41a13.59,13.59,0,0,1-1.34-16.85l20.31,20.78-5.13,10.78Z" opacity="0.08"></path>
            <path d="M212.37,216.26H184.82a83.06,83.06,0,0,1-7.15-5.67h0c-2.55-.69-3.74-5-1.6-5.6s3.39,2.83,3.39,2.83,17.05,11.76,22.07,5.1c1.55-2.06-.29-5.35-1.67-8-2.5-4.71-10.44-12.91-10.49-20.25,0-6,7.07-9.25,11.77-5.51,6.36,5,15.3,13.55,11.81,34.43A24.13,24.13,0,0,1,212.37,216.26Z" fill="#f4a28c"></path>
            <path d="M191.93,174.81s.79-2.12-3.17-2.75-7.22.92-6,3.79C182.72,175.85,185.61,178.38,191.93,174.81Z" fill={color ? color : "#CC3366"}></path>
            <path d="M191.93,174.81s.79-2.12-3.17-2.75-7.22.92-6,3.79C182.72,175.85,185.61,178.38,191.93,174.81Z" fill="#fff" opacity="0.46"></path>
            <circle cx="249.11" cy="229.07" r="10.1" opacity="0.09"></circle>
        </svg>
    )
}
