import React from 'react';
//
import Home from './pages/Home';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <Home/>
  );
}

export default App;
