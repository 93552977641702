import React from 'react'
//
import { toCurrency } from '../../../../functions/toCurrency';
//
import Botao from '../../../../components/Botao';
//
import { constants } from '../../../../constants';
//
import {
    FaSimCard,
    FaWhatsapp,
    FaLaptopMedical,
    FaGraduationCap,
    FaMobileAlt,
    FaMap,
    FaCheckCircle,
    FaCartPlus,
    FaUserCheck,
} from "react-icons/fa";
import { IoSpeedometer } from "react-icons/io5";
//
import styles from './card-plano.module.css';

export default function CardPlano({ plano }) {
    return (
        <div className={styles['container']}>
            <div className={styles['header']}>
                <div className={styles['chip-icon']}>
                    <FaSimCard />
                </div>
                <span className={styles['empresa-chip']}>
                    {constants.texts.nomeEmpresa} CHIP
                </span>
                <span className={styles['nome-plano']}>
                    {plano.description || '-'}
                </span>
            </div>

            <ul className={styles['lista-beneficios']}>
                <li className={styles['beneficio']}>
                    <FaMobileAlt />
                    <span>
                        {
                            plano.min
                                ? (plano.min === '999'
                                    ? 'Ligações ILIMITADAS para qualquer DDD'
                                    : `${plano.min} Minutos de Ligações Para Qualquer DDD`
                                )
                                : 'Minutos de Ligações Para Qualquer DDD'
                        }
                    </span>
                </li>
                <li className={styles['beneficio']}>
                    <FaWhatsapp />
                    <span>Whatsapp Grátis</span>
                </li>
                <li className={styles['beneficio']}>
                    <FaLaptopMedical />
                    <span>Orientação Médica Ilimitada por Chat</span>
                </li>
                <li className={styles['beneficio']}>
                    <FaGraduationCap />
                    <span>Portal EAD Grátis - Cursos Certificados</span>
                </li>
                <li className={styles['beneficio']}>
                    <FaMobileAlt />
                    <span>Aplicativo Skeelo Light</span>
                </li>
                <li className={styles['beneficio']}>
                    <FaMap />
                    <span>Maior Cobertura do Brasil</span>
                </li>
                <li className={styles['beneficio']}>
                    <FaCheckCircle />
                    <span>Sem Multa</span>
                </li>
                <li className={styles['beneficio']}>
                    <FaCartPlus />
                    <span>Acúmulo de Gigas</span>
                </li>
                <li className={styles['beneficio']}>
                    <FaUserCheck />
                    <span>Sem Fidelidade</span>
                </li>
                <li className={styles['beneficio']}>
                    <IoSpeedometer />
                    <span>Sem Limite de Velocidade</span>
                </li>
            </ul>

            <div className={styles['valor-plano']}>
                <span className={styles['txt-por']}>Por</span>
                <span className={styles['valor']}>
                    {toCurrency(plano.value)}/mês
                </span>
            </div>

            {constants.links.linkPedirChip &&
                <Botao
                    type='link'
                    color='primary'
                    icon={<FaSimCard />}
                    marginLeft={0.5}
                    text='PEDIR CHIP'
                    href={constants.links.linkPedirChip}
                    style={{ marginTop: '1rem' }}
                />
            }
        </div>
    )
}
