import React from 'react'
//
import BotaoWhats from '../../components/BotaoWhats';
import Navbar from '../../components/Navbar';
import BannerApp from './BannerApp';
import BannerFaq from './BannerFaq';
import BannerPedirChip from './BannerPedirChip';
import BannerPlanos from './BannerPlanos';
import BannerQuemSomos from './BannerQuemSomos';
import BannerSobreNos from './BannerSobreNos';
import Footer from '../../components/Footer';
//
import styles from './home.module.css';

export default function Home() {
  return (
    <section className={styles['container']}>
        <BotaoWhats/>
        <Navbar/>
        <BannerPedirChip/>
        <BannerPlanos/>
        <BannerQuemSomos/>
        <BannerApp/>
        <BannerSobreNos/>
        <BannerFaq/>
        <Footer/>
    </section>
  );
}