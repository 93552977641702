import React, { useState } from 'react';
//
import { FaPlus } from "react-icons/fa";
//
import styles from './pergunta-faq.module.css';

export default function PerguntaFaq({ title, text }) {

    //STATES
    const [showText, setShowText] = useState(false);

    const styleIconShow = {
        transform: 'rotateZ(225deg)',
        transition: 'all 0.5s ease-in-out',
    }
    const styleIconHidden = {
        transform: 'rotateZ(0deg)',
        transition: 'all 0.5s ease-in-out',
    }

    const styleTxtShow = {
        maxHeight: '30rem',
        padding: '1rem',
        borderTop: '1px solid #eeeeeeb4'
    }

    //BODY
    return (
        <section className={styles['container']}>

            <div
                className={styles['header']}
                onClick={() => { setShowText(!showText) }}
            >
                <span className={styles['btn-header']}>
                    <FaPlus
                        style={showText
                            ? styleIconShow
                            : styleIconHidden
                        }
                    />
                </span>
                <span className={styles['title-header']}>
                    {title ? title : 'Lorem Ipsum'}
                </span>
            </div>

            <div
                className={styles['text']}
                style={showText
                    ? styleTxtShow
                    : {}
                }
            >
                {text ? text : 'Lorem Ipsum Emmet'}
            </div>

        </section>
    )
}
