import React from 'react'
//
import styles from './default-header.module.css';

export default function DefaultHeader({
  icon,
  subtitle,
  title,
  txt,
  dark,
}) {
  return (
    <div className={styles['header']}>
      {icon}
      {subtitle &&
        <span
          className={styles['subtitle']}
        >
          {subtitle}
        </span>
      }
      {title &&
        <span
          className={dark ? styles['title-dt'] : styles['title']}
        >
          {title}
        </span>
      }
      {txt &&
        <span
          className={dark ? styles['txt-dt'] : styles['txt']}
        >
          {txt}
        </span>
      }
    </div>
  )
}
